import React from 'react';
import './HandBackground.scss';

function HandBackground(props) {
    return (
        <div className='hand-background'>
            <div className="background" />
            <div className="container main-text">
                <img className="navbar-brand logo" src="assets/images/logos/white.png" alt="" />
                <h1>Fulfilling Dreams</h1>
                <h3>"The believer's shade on the day of Resurrection will be his charity."</h3>
                <p>AL-TIRMIDHI, HADITH 604</p>
                {/* <div className="donate-container">
                    <a id="donate" href="/contact-us" className="buttonStyle">Donate Now</a>
                </div> */}
            </div>
        </div>
    );
}

export default HandBackground;