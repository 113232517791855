import React from 'react';
import { ContactForm } from '../../forms';
import './Contact.scss';

function Contact(props) {
    return (
        <div className='contact-us-page'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col text-center">
                        {/* <img src="assets/images/logos/black.png" alt="" class="logo"> */}
                        <h1>Have any queries?</h1>
                        <div className="row justify-content-center icons">
                            <div className="col-md-2 text-center">
                                <a target="_blank" href="https://wa.me/+923070208169" rel="noreferrer">
                                    <i className="fa fa-whatsapp icon whatsapp" />
                                </a>
                            </div>
                            <div className="col-md-3 text-center">
                                <a target="_blank" href="https://www.facebook.com/khuwabekishwar" rel="noreferrer">
                                    <i className="fa fa-instagram icon instagram" />
                                </a>
                            </div>
                            <div className="col-md-2 text-center">
                                <a target="_blank" href="https://www.facebook.com/khuwabekishwar" rel="noreferrer">
                                    <i className="fa fa-facebook icon facebook" />
                                </a>
                            </div>
                        </div>
                        <h1>or</h1>
                        <h1 style={{marginBottom: '3rem'}}>Email us at <a style={{ color: '#8CAB44', textDecoration: 'none' }} href="mailto:info@kekf.pk">info@kekf.pk</a></h1>
                        {/* <app-contact /> */}
                        {/* <ContactForm /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;