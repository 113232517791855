import React from 'react';
import './OurStory.scss';

function OurStory(props) {
    return (
        <div className='our-story'>
            <div className="container-fluid">
                <div className="container text-center">
                    <h5>Humaari Kahani</h5>
                    <h1>Our Story</h1>
                    <p>Khuwab-e-Kishwar Foundation (KEKF), a tribute to the legacy of Kishwar Rizwan, stands as a beacon of her unwavering dedication to the well-being of others. Inspired by the footsteps of her grandmother, Sanya Ahmed Sultan established KEKF, bringing to life her grandmother's vision of tireless service for humanity.
                        <br /><br />
                        Witnessing her grandmother's impact through community service, Sanya was moved to create KEKF, a platform where the aspiration to make a difference could be shared by all.
                        <br /><br />
                        Through our 'teaching a man how to catch a fish' initiative, we provide opportunities for families to become self-reliant, through empowering families with income sources, education, and skills.
                        <br /><br />
                        But our reach goes beyond that. In times of crisis, whether it's the COVID-19 pandemic or natural disasters, our 'catching a man a fish' approach comes into play. We execute rescue and relief operations, ensuring that families have their immediate needs met during the most challenging times.
                        <br /><br />
                        KEKF envisions a society where each family thrives independently while being supported through tough times. We believe in building a stronger, self-sufficient Pakistan, one family at a time.</p>
                </div>
            </div>
        </div>
    );
}

export default OurStory;