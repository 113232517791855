import React from 'react';
import { HandBackground, CurrentProject, PresidentMessage, InterButtons, Blog } from './components';
import './Home.scss';

function Home(props) {
    return (
        <div>
            <HandBackground />
            {/* <CurrentProject /> */}
            <PresidentMessage />
            <div className="container-fluid help-cause">
                <h1>#HELPFORCAUSE</h1>
            </div>
            <InterButtons />
            {/* <Blog /> */}
        </div>
    );
}

export default Home;