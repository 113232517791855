import React from 'react';
import './OurMission.scss';

function OurMission(props) {
    return (
        <div className='our-mission'>
            <div className="container-fluid">
                <div className="container text-center">
                    <h5>Humaara Khuwaab</h5>
                    <h1>Our Mission</h1>
                    <p>
                        At KEKF, our mission is to empower Pakistanis to become self-reliant and independent. We believe in fostering a nation where every individual possesses the tools to lead a self-sufficient life.
                        <br /><br />
                        We're dedicated to providing sustainable sources of income, such as rickshaws, motorcycles, and fruit carts, enabling individuals to support themselves and their families. Beyond financial independence, we prioritize basic education and multifaceted skill development. Our skill centers for women and computer labs in government schools aim to equip people with a broad range of abilities, opening doors to diverse opportunities.
                        <br /><br />
                        Looking ahead, our aspirations encompass even greater horizons. We envision charity hospitals and schools that provide easy access to quality education and healthcare for underprivileged individuals. By establishing these vital institutions, we aim to bridge gaps and create a more equitable society, where quality education and healthcare are rights, not privileges.
                        <br /><br />
                        At KEKF, our mission is to realize a self-reliant, educated, and healthy Pakistan, where individuals flourish, families thrive, and communities prosper.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default OurMission;