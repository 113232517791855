import React from 'react';
import './Departments.scss';

function Departments(props) {
    return (
        <div className='departments'>
            <div className="container-fluid">
                <div className="container">
                    <h1 className='text-center'>Departments</h1>
                    <h5>Education</h5>
                    <p>Our Education Department strives to enroll children from underprivileged families into private schools, covering all costs. We also pay school fee of children already in schools who can't afford it. We're also working actively to improve government and charity-based schools, with a special emphasis on creating computer labs to boost digital literacy and provide equal educational opportunities.</p>
                    <h5>Healthcare</h5>
                    <p>Our Healthcare Department eases the burden of medical expenses by covering hospital bills, providing essential medications, and organizing free medical camps. This ensures that quality healthcare is within reach for everyone.</p>
                    <h5>Disaster Relief</h5>
                    <p>When disaster strikes, our Disaster Relief Department responds promptly. We lend a hand to households affected by various emergencies, including COVID lockdowns, floods, bomb blasts, and plane crashes. Our emergency aid includes relief camps, cooked meals, ration bags, and assistance in rebuilding homes.</p>
                    <h5>Provision</h5>
                    <p>The Provision Department assists families with housing costs, utility bills, and provide essential household items. From distributing groceries and clothing, to organising iftar dastarkhuwans and ration drives.</p>
                    <h5>Accommodation</h5>
                    <p>Our Accommodation Department  supports families with housing needs, offering rent assistance and ensuring access to shelter. We also work to reconstruct houses affected by natural disasters such as floods.</p>
                    <h5>Employment Generation</h5>
                    <p>The Employment Generation Department is determined to break the cycle of extreme poverty. Through skill development centers, we empower jobless communities with essential skills, aiding in finding employment or becoming self-employed micro-entrepreneurs. Our aim is to create opportunities that empower individuals to enhance their quality of life.</p>
                    <h1 className='text-center'>Fundamental Ideas</h1>
                    <h5>Teaching a Man How to Catch a Fish</h5>
                    <p>Our commitment to self-sufficiency is embodied in the concept of teaching a man how to catch a fish. This principle drives our efforts in digital literacy, skill development, education, entrepreneurship, and employment generation. Our initiatives include providing income sources like rickshaws, motorcycles, and carts, establishing computer labs in government schools, and creating skill development centers for women.</p>
                    <h5>Catching a Man a Fish</h5>
                    <p>In times of immediate need, we embrace the concept of catching a man a fish. This approach forms the foundation of our one-time assistance initiatives, providing essential relief to individuals and families facing challenging circumstances. We distribute rations during COVID lockdowns, execute flood relief operations, cover household bills, school fees, and hospital expenses.</p>
                </div>
            </div>
        </div>
    );
}

export default Departments;