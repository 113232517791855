import React from 'react';
import { OurStory, OurMission, Departments } from './components';
import './About.scss';

function About(props) {
    return (
        <div>
            <OurStory />
            <OurMission />
            {/* <Departments /> */}
        </div>
    );
}

export default About;