import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss'

function Footer(props) {
    return (
        <footer className="footer-distributed">
            <div className="footer-left">
                <h3>KE<span>KF</span></h3>
                <p className="footer-links">
                    <Link to="/" className="link-1">Home</Link>
                    <Link to="/about" className="link-2">About</Link>
                    {/* <Link to="/drives" className="link-3">Drives</Link>
                    <Link to="/blog" className="link-4">Blog</Link>
                    <Link to="/contact-us" className="link-5">Contact Us</Link> */}
                    {/* <a href="/diy">DIY</a> */}
                    {/* <a href="/contact-us">Contact Us</a> */}
                </p>
                <p className="footer-company-name">KEKF © 2019</p>
            </div>
            <div className="footer-center">
                <div className="special">
                    <i className="fa fa-phone" />
                    <p><a rel="noreferrer" target="_blank" href="https://wa.me/+923070208169">+92-307-0208169</a></p>
                </div>
                <div className="special">
                    <i className="fa fa-envelope" />
                    <p><a href="mailto:info@kekf.pk">info@kekf.pk</a></p>
                </div>
            </div>
            <div className="footer-right">
                <p className="footer-company-about">
                    <span>Khuwab-e-Kishwar Foundation</span>
                    KEKF is a youth-led NGO that was founded by a 17 year old in 2019. KEKF's founder Sanya Ahmed Sultan was recognised as a Youth Icon by the government of Pakistan for creating societal impact and inspiring the youth.
                    <br /><br />
                    KEKF's work is based on 2 principles:<br />
                    <ol>
                        <li>Teaching a man how to catch a fish (making a family independent)</li>
                        <li>Catching a man a fish (one time help & rescue operations)</li>
                    </ol>
                </p>
                <div className="footer-icons">
                    <a rel="noreferrer" target="_blank" href="https://www.facebook.com/khuwabekishwar"><i className="fa fa-facebook" /></a>
                    <a rel="noreferrer" target="_blank" href="https://wa.me/+923070208169"><i className="fa fa-whatsapp" /></a>
                    <a rel="noreferrer" target="_blank" href="https://www.instagram.com/kekf.pk"><i className="fa fa-instagram" /></a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;