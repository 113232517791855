import React from 'react';
import {
    Switch,
    Route,
    useLocation,
} from "react-router-dom";
import { WebNavbar, Sidebar, Footer } from './components';
import { Home, About, Drives, Blog, Contact, Departments } from './pages';
// import Auth from './auth/Auth';
// import { ComingSoon } from './pages';
// import { Dashboard } from './dashboard';
// import CartContext from './contexts/cart';
// import DiscountContext from './contexts/discount';
// import api from './api';
import {
    TransitionGroup,
    CSSTransition
} from "react-transition-group";
//   import './App.scss';
// import './form.scss';
// import './global.scss';
// import api from './api';
// import UserContext from './contexts/userContext';
// import CartCountContext from './contexts/cartCountContext';
// import { Dashboard } from './dashboard';

function RoutesFile(props) {

    let location = useLocation();

    return (
        <div>
            <WebNavbar />
            <TransitionGroup>
                <CSSTransition
                    key={location.key}
                    classNames="page"
                    timeout={300}
                >
                    <div className="page">
                        {/* <MainNavbar positionStyle={positionStyle} /> */}
                        <Switch location={location}>
                            <Route path="/contact-us">
                                <Contact />
                                <Sidebar />
                                <Footer />
                            </Route>
                            {/* <Route path="/blog">
                                <Blog />
                                <Sidebar />
                                <Footer />
                            </Route>
                            <Route path="/drives">
                                <Drives />
                                <Sidebar />
                                <Footer />
                            </Route> */}
                            <Route path="/about">
                                <About />
                                <Sidebar />
                                <Footer />
                            </Route>
                            <Route path="/departments">
                                <Departments />
                                <Sidebar />
                                <Footer />
                            </Route>
                            <Route path="/">
                                <Home />
                                <Sidebar />
                                <Footer />
                            </Route>
                        </Switch>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
}

export default RoutesFile;
