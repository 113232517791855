import React from 'react';
import './InterButtons.scss';

function InterButtons(props) {
    return (
        <div className='inter-buttons'>
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-4">
                        <img src="/images/interButtons/Volunteer-Clipart.png" alt="Volunteer" />
                        <p><a href="mailto:info@kekf.pk">become a volunteer</a></p>
                    </div>
                    <div className="col-md-4">
                        <img src="/images/interButtons/Donate-Clipart.png" alt="Volunteer" />
                        <p><a href="mailto:info@kekf.pk">become a donor</a></p>
                    </div>
                    <div className="col-md-4">
                        <img src="/images/interButtons/Support-Clipart.png" alt="Volunteer" />
                        <p><a href="mailto:info@kekf.pk">become a sponsor</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InterButtons;