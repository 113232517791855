import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import { LinkButton } from '../';
import './WebNavbar.scss';

function WebNavbar(props) {

    const onClickHamburger = () => {
        const sidebar = document.getElementById('client-sidebar');
        const check = sidebar.classList.contains('client-sidebar-closed');
        const notActive = sidebar.classList.contains('client-sidebar-not-active');
        if (notActive) {
            sidebar.classList.remove('client-sidebar-not-active');
            sidebar.classList.add('client-sidebar-open');
        } else {
            if (check) {
                sidebar.classList.remove('client-sidebar-closed');
                sidebar.classList.add('client-sidebar-open');
            } else {
                sidebar.classList.add('client-sidebar-closed');
                sidebar.classList.remove('client-sidebar-open');
            }
        }
    }

    let color = 'black';
    let className = 'relative';
    if (window.location.pathname === '/') {
        color = 'white';
        className = 'absolute';
    }

    return (
        <div className={`web-navbar ${className}`}>
            <Navbar bg="transparent">
                <Container fluid>
                    <Link to="/">
                        {
                            className === 'absolute' ?
                                <img className="logo" src="/images/logos/white.png" alt="" />
                                :
                                <img className="logo" src="/images/logos/black.png" alt="" />
                        }
                    </Link>
                    <Nav
                        className="me-auto my-2 my-lg-0"
                    >
                    </Nav>
                    <div className='hide-992'>
                        <div className="d-flex navbar-links">
                            {/* <LinkButton to="/careers" text="Careers" className="transparent-bg" />
                            <LinkButton to="/build-with-us" text="Build with Us" className="gradient-txt" /> */}
                            <Link className='nav-link' to="/">Home</Link>
                            <Link className='nav-link' to="/about">About</Link>
                            <Link className='nav-link' to="/departments">Departments</Link>
                            {/* <Link className='nav-link' to="/drives">Drives</Link>
                            <Link className='nav-link' to="/blog">Blog</Link> */}
                            <Link className='nav-link' to="/contact-us">Contact Us</Link>
                        </div>
                    </div>
                    <div className='unhide-992'>
                        <div className="d-flex">
                            <i id="client-sidebar-hamburger" style={{ color: color }} className="fa fa-bars" onClick={onClickHamburger} />
                        </div>
                    </div>
                </Container>
            </Navbar>
        </div>
    );
}

export default WebNavbar;