import React from 'react';
import './PresidentMessage.scss';

function PresidentMessage(props) {
    return (
        <div className='president-message'>
            <div className="container-fluid">
                <div className="container text-center">
                    <div className="col">
                        <h3>Sanya Nauman Ahmed Sultan</h3>
                        <h5>President & Founder</h5>
                        <h6>Karachi, Pakistan</h6>
                        <p>
                            In the name of Allah, the most beneficent, the most merciful.
                            <br /><br />
                            Welcome to KEKF Foundation!
                            <br /><br />
                            I am honored to share the remarkable journey of KEKF with you. Over the past four years, we have impacted the lives of more than half a million families across Pakistan.
                            <br /><br />
                            It is with immense pride that I reflect on the evolution of KEKF. From its inception, we have remained steadfast in our principles: to empower families with sustainable opportunities and to extend a helping hand in times of need. Our dedication has materialized through initiatives like providing livelihoods to the jobless, establishing computer labs in underserved schools, and enabling women to acquire essential skills that empower them to excel.
                            <br /><br />
                            In times of crisis, such as the COVID-19 pandemic and devastating floods, KEKF has risen to the challenge, executing rescue and relief operations, providing essential supplies, and supporting those in need with their bills and fees.
                            <br /><br />
                            I am proud to share that our organization's impact were recognized by the government of Pakistan when I was honored as a Youth Icon. This recognition is a testament to the power of youth-led initiatives, and it further fuels our commitment to inspiring and uplifting the next generation.
                            <br /><br />
                            KEKF is entirely a youth-led organization, a fact that fills me with hope and optimism for the future. Our young and passionate team is united by a shared vision of creating lasting change in society.
                            <br /><br />
                            Our mission to alleviate suffering and build a stronger Pakistan is at the core of everything we do. Together, we believe in eradicating poverty, nurturing education, and fostering self-reliance for a better Pakistan.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PresidentMessage;